import { styled } from '@joovence/ui';

export const NotFoundStyled = styled('div')(({ theme }) => ({
  width: '100%',

  marginTop: theme.spacing(8),

  textAlign: 'center',

  p: {
    color: theme.palette.error.main
  }
}));
