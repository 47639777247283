import { styled } from '@joovence/ui';

export const LoadingStyled = styled('p')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  gap: theme.spacing(1),
  marginTop: theme.spacing(4)
}));
