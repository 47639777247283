import { FC } from 'react';
import { ButtonProps as JoovenceButtonProps, MUI } from '@joovence/ui';

import { ButtonStyled } from './Styled';

interface ButtonProps extends JoovenceButtonProps {
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  children,
  loading,
  disabled,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ButtonStyled {...props} disabled={loading || disabled}>
      {loading ? (
        <MUI.CircularProgress size={24} color="secondary" />
      ) : (
        children
      )}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  loading: false
};
